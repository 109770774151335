.pageTitle {
  text-align: center;
  font-weight: bold;
  color: violet;
}

.page {
  background-color: black;
  padding-top: 20px;
  min-height: 100vh;
  text-align: justify;
  hyphens: auto;
  font-size: 22px;
  color: violet;
  margin-left: 210px;
  margin-right: 210px;
}

.home {
  padding-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sectionHead {
  color: purple;
}

.controlName {
  color: orange;
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 0;
  font-size: 24px;
  font-weight: 600;
}

.controlName span {}

.dialogBox {
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  background: orange;
  color: purple;
  width: 100%;
  height: 100%;
  overflow: scroll;
  text-align: justify;
  hyphens: auto;
  justify-content: center;
}

.warningBox {
  background: purple;
  color: orange;
  text-align: left;
}

.warningBox h2 {
  justify-content: center;
  text-align: center;
}

.indexThumb {
  font-size: 16px;
  text-align: center;
  width: 200px;
}

.indexThumb p {
  text-align: center;
  hyphens: auto;
}

.indexThumb a {
  color: orange;
}


.indexGrid {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fit, 200px);
  justify-content: center;
  width: 100%;
}

h4.line, h5.line, h6.line {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid violet;
  line-height: 0.1em;
  margin: 15px 0 15px;
}

span.line {
  color: violet;
  background: #330033;
  padding: 0 10px;
}

html, body {
  height: 100%;
  background-color: black;
  color: orange;
}

div.fillScreen {
  position: fixed;
  display: flex;
  margin: 0;
  padding: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  background-color: black;
}

#application {
  z-index: 2;
}

#application.mouseGone {
  cursor: none;
}

.sideBar {
  height: 100%;
  width: 200px;
  position: fixed;
  z-index: 101;
  top: 0;
  background-color: #330033;
  opacity: 0;
  overflow-x: hidden;
  transition: 1s;
}

.barOpen {
  opacity: 1;
}

.barClosed {
  opacity: 0;
}

#leftBar.barOpen {
  left: 0;
}

#leftBar.barClosed {
  left: -200px;
}

#rightBar.barOpen {
  right: 0;
}

#rightBar.barClosed {
  right: -200px;
}

.barTab {
  display: flex;
  position: fixed;
  z-index: 100;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0;
  top: 0;
  bottom: 0;
  transition-property: z-index, width, left, right, opacity;
  transition-duration: 1s, 1s, 1s, 1s, .7s;
  transition-timing-function: linear, ease, ease, ease, linear;
}

.fullButton {
  width: 200px;
}

.halfButton {
  width: 100px;
}

.tabOpen {
  opacity: 0;
  z-index: -25;
}

.tabClosed {
  opacity: 1;
  z-index: 100;
}

.tabHidden {
  opacity: 0;
}

#leftTab.tabOpen {
  left: 200px;
  width: 0;
}

#leftTab.tabClosed {
  left: 0;
  width: 50;
}

#rightTab.tabOpen {
  right: 200px;
}

#rightTab.tabClosed {
  right: 0;
}

.numberWidget {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
}

input {
  width: 200px;
}

.Dropdown-disabled {
  color: lightgray;
}